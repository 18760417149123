/* src/components/Header/Header.css */
.header {
  background: var(--background-white);
  border-bottom: 1px solid var(--border-light);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  height: 64px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  flex-shrink: 0;
}

.logo {
  height: 28px;
  width: auto;
  transition: transform 0.2s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.location-selector {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px var(--spacing-md);
  background: var(--background-light);
  border-radius: var(--radius-lg);
  color: var(--primary-dark);
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease;
  cursor: pointer;
  height: 36px;
}

.location-selector:hover {
  background: var(--hover-overlay);
}

.location-selector .loading {
  opacity: 0.7;
  font-style: italic;
}

.location-icon {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.search-container {
  flex: 1;
  max-width: 480px;
  position: relative;
  display: flex;
  align-items: center;
  height: 36px; /* Fixed height to match other elements */
}

.search-input {
  width: 100%;
  height: 36px; /* Match container height */
  padding: 0 36px 0 12px; /* Adjusted padding */
  background: var(--background-light);
  border: 1px solid transparent;
  border-radius: var(--radius-lg);
  font-size: 14px;
  transition: all 0.2s ease;
  color: var(--text-primary);
  line-height: 36px; /* Match height for vertical centering */
}

.search-input:hover {
  background: var(--hover-overlay);
}

.search-input:focus {
  outline: none;
  background: var(--background-white);
  border-color: var(--border-medium);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.search-input::placeholder {
  color: var(--text-secondary);
}

.search-icon {
  position: absolute;
  right: var(--spacing-md);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px; /* Fixed size for the icon */
}

.cta-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.cta-button {
  padding: 0 20px;
  background: var(--primary-dark);
  color: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: var(--radius-lg);
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;
  font-size: 14px;
  border: none;
  height: 36px; /* Match other elements height */
  line-height: 36px; /* Center text vertically */
  display: inline-flex;
  align-items: center;
}

.cta-button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

@media (max-width: 1024px) {
  .search-container {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .header-content {
    padding: 0 var(--spacing-md);
    gap: var(--spacing-md);
    height: 56px;
  }

  .logo {
    height: 24px;
  }

  .search-container {
    flex: 1;
    min-width: 0;
  }

  .location-selector {
    font-size: 13px;
    padding: 6px var(--spacing-sm);
    height: 32px;
  }

  .search-input,
  .cta-button {
    height: 32px;
    line-height: 32px;
  }

  .cta-button {
    padding: 0 16px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .header-content {
    padding: 0 var(--spacing-sm);
    height: 48px;
  }

  .logo {
    height: 22px;
  }

  .location-selector {
    display: none;
  }

  .search-input {
    font-size: 13px;
  }

  .cta-button {
    padding: 0 12px;
    font-size: 12px;
  }
}