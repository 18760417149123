/* src/components/RestaurantCard/RestaurantCard.css */
.restaurant-card {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.restaurant-card-inner {
  border-radius: 12px;
  overflow: hidden;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.restaurant-card-inner:hover {
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  transform: translateY(-2px);
}

.restaurant-image {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 ratio */
  overflow: hidden;
  background: #f8f8f8;
}

.restaurant-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.restaurant-card-inner:hover .restaurant-image img {
  transform: translate(-50%, -50%) scale(1.05);
}

.restaurant-info {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.restaurant-name {
  font-size: 18px;
  font-weight: 600;
  color: #202125;
  margin: 0 0 12px 0;
}

.category-hours-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.category-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

.category-tag {
  background: rgba(66, 106, 141, 0.1);
  color: var(--primary-dark);
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
}

/* Updated opening hours styles */
.opening-hours {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 16px;
  white-space: nowrap;
  margin-left: 0;
}

.opening-hours.open {
  background: rgba(0, 160, 130, 0.1);
  color: #00a082;
}

.opening-hours.closed {
  background: rgba(255, 77, 79, 0.1);
  color: #ff4d4f;
}

.clock-icon {
  font-size: 12px;
}

.location-info {
  margin-bottom: 16px;
  font-size: 13px;
  color: #666;
}

.address {
  display: flex;
  align-items: center;
  gap: 4px;
}

.pin-icon {
  font-size: 12px;
}

.distance {
  color: #666;
  font-weight: 500;
}

.button-container {
  margin-top: auto;
  width: 100%;
}

.order-button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  background: var(--primary-dark);
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
}

.order-button:hover {
  background: var(--primary-light);
  transform: translateY(-1px);
}

.coming-soon-button {
  display: block;
  width: 100%;
  padding: 12px 20px;
  background: #e5e5e5;
  color: #666;
  text-align: center;
  text-decoration: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: not-allowed;
}

.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(23, 57, 84, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .restaurant-name {
    font-size: 16px;
  }
  
  .category-tag {
    font-size: 12px;
  }
  
  .opening-hours {
    font-size: 12px;
  }
  
  .location-info {
    font-size: 12px;
  }
  
  .order-button,
  .coming-soon-button {
    font-size: 13px;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .restaurant-info {
    padding: 12px;
  }
  
  .restaurant-name {
    font-size: 15px;
    margin-bottom: 8px;
  }
  
  .category-tag {
    font-size: 11px;
    padding: 3px 8px;
  }
  
  .opening-hours {
    font-size: 11px;
    padding: 3px 8px;
  }
}