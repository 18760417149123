/* src/index.css */
:root {
  /* Primary colors */
  --primary-dark: #173954;
  --primary-light: #426a8d;
  --primary-hover: #2c4d6d;
  
  /* Secondary/Accent colors */
  --accent-blue: #009de0;
  --success-green: #00a082;
  --error-red: #ff4d4f;
  
  /* Background colors */
  --background-light: #f8f9fa;
  --background-white: #ffffff;
  --hover-overlay: rgba(23, 57, 84, 0.05);
  
  /* Text colors */
  --text-primary: #202125;
  --text-secondary: #666666;
  --text-light: #8c8c8c;
  
  /* Border colors */
  --border-light: rgba(0, 0, 0, 0.08);
  --border-medium: rgba(0, 0, 0, 0.12);
  
  /* Shadow styles */
  --shadow-sm: 0 1px 2px rgba(23, 57, 84, 0.04);
  --shadow-md: 0 8px 24px rgba(23, 57, 84, 0.12);
  
  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  
  /* Border radius */
  --radius-sm: 8px;
  --radius-md: 12px;
  --radius-lg: 16px;
  --radius-xl: 24px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-light);
  color: var(--text-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  position: relative;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

/* Common button styles */
button, .button {
  cursor: pointer;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

button:disabled, .button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Common hover effects */
.hover-lift {
  transition: transform 0.2s ease;
}

.hover-lift:hover {
  transform: translateY(-2px);
}

/* Common card styles */
.card {
  background: var(--background-white);
  border-radius: var(--radius-md);
  border: 1px solid var(--border-light);
  box-shadow: var(--shadow-sm);
}

.card:hover {
  border-color: var(--border-medium);
  box-shadow: var(--shadow-md);
}

/* Common tag styles */
.tag {
  padding: 4px 10px;
  border-radius: var(--radius-lg);
  font-size: 13px;
  font-weight: 500;
}

/* Utility classes */
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}