.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.main-content {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
  overflow-x: hidden;
}

.page-title {
  font-size: 28px;
  font-weight: 700;
  margin: 24px 0;
  color: var(--text-primary);
}

.filters {
  margin: 16px 0 24px;
}

.category-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.category-buttons::-webkit-scrollbar {
  display: none;
}

.category-btn {
  padding: 8px 16px;
  border-radius: var(--radius-xl);
  border: none;
  background: var(--category-bg);
  color: var(--category-text);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex: 0 0 auto;
}

.category-btn:hover {
  background: rgba(66, 106, 141, 0.15);
  transform: translateY(-1px);
}

.category-btn.active {
  background: var(--primary-dark);
  color: white;
}

/* Toggle switch styling */
.toggle-open {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  background-color: var(--border-light);
  border-radius: var(--radius-xl);
  transition: all 0.2s ease;
}

.slider:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background: white;
  border-radius: 50%;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

input:checked + .slider {
  background: var(--primary-light);
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.toggle-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

/* Restaurant grid styling */
.restaurants-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
}

/* Main content search container styling - renamed to avoid conflicts */
.main-search-container {
  position: relative;
  margin-bottom: 16px;
}

.main-search-input {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid var(--border-light);
  border-radius: var(--radius-lg);
  font-size: 14px;
  transition: all 0.2s ease;
}

.main-search-input:focus {
  outline: none;
  border-color: var(--primary-light);
  box-shadow: 0 0 0 3px rgba(66, 106, 141, 0.1);
}

.main-search-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
}

/* Updated media queries */
@media (max-width: 1024px) {
  .restaurants-grid {
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media (max-width: 768px) {
  .main-content {
    padding: 0 var(--spacing-md);
  }

  .restaurants-grid {
    gap: 16px;
  }

  .page-title {
    font-size: 24px;
    margin: 20px 0;
  }

  .filters {
    margin: 12px 0 20px;
  }

  .category-buttons {
    gap: 8px;
    padding: 4px;
  }

  .category-btn {
    font-size: 13px;
    padding: 6px 12px;
  }

  .main-search-input {
    padding: 10px 14px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .main-content {
    padding: 0 var(--spacing-sm);
  }

  .restaurants-grid {
    gap: 12px;
    grid-template-columns: 1fr;
  }

  .category-buttons {
    gap: 6px;
  }

  .category-btn {
    font-size: 12px;
    padding: 6px 10px;
  }

  .toggle-label {
    font-size: 13px;
  }

  .page-title {
    font-size: 22px;
    margin: 16px 0;
  }
}

/* Animation classes */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms ease, transform 300ms ease;
}