/* src/components/Footer/Footer.css */
.footer {
  width: 100%;
  padding: 20px 16px;
  margin-top: 40px;
  background: linear-gradient(to bottom, transparent, rgba(23, 57, 84, 0.02));
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .footer {
    padding: 16px;
    margin-top: 32px;
  }

  .footer-content {
    font-size: 13px;
  }
}